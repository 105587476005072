<template>

  <div>
    <!-- Summary -->
    <b-row>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="ActivityIcon"
          color="danger"
          :statistic="summary.pending"
          statistic-title="Pending Review"
        />
      </b-col>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="CheckSquareIcon"
          color="success"
          :statistic="summary.reviewed"
          statistic-title="Reviewed"
        />
      </b-col>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="MinusSquareIcon"
          color="info"
          :statistic="summary.unsubmitted"
          statistic-title="Unsubmitted"
        />
      </b-col>
      <b-col lg="3" sm="3">
        <statistic-card-horizontal
          icon="GridIcon"
          :statistic="summary.total"
          statistic-title="Total"
        />
      </b-col>
    </b-row>

    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row
          cols="12"
          md="4"
          class="mb-md-0"
        >
          <!-- radio -->
          <b-form-radio-group
            class="ml-1 list item-view-radio-group"
            buttons
            button-variant="outline-primary"
            @input="fastSelected"
          >
            <b-form-radio value="3">
              All
            </b-form-radio>
            <b-form-radio value="1">
              Pending Review
            </b-form-radio>
            <b-form-radio value="2">
              Reviewed
            </b-form-radio>
          </b-form-radio-group>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body>
      <b-table
        class="position-relative"
        responsive
        :fields="transactionsTableFields"
        :items="transactions"
        primary-key="trade_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(merchant_no)="data">
          <!-- , app_id: data.item.merchant_no , out_trade_no: data.item.merchant_no , zone_id: tzSelected -->
          <b-link
            v-if="data.item.submitted !== '0'"
            :to="{ name: 'kyc-detail', query:{merchant_no: data.item.merchant_no, id: data.item.id} }"
            target="_blank"
            class="font-weight-bold d-block td-merchant-no"
          >
            {{ data.item.merchant_no }}
          </b-link>
          <b-link v-else :id="'link-merchant-no'+data.item.merchant_no" class="font-weight-bold d-block td-merchant-no">
            {{ data.item.merchant_no }}
            <b-tooltip :target="'link-merchant-no'+data.item.merchant_no" title="Merchant KYC info has not been submitted." placement="bottom">
              Merchant KYC info has not been submitted.
            </b-tooltip>
          </b-link>
        </template>

        <template #cell(submitted)="data">
          {{ data.item.is_submit_yes === 1? 'YES':'NO' }}
          <!-- {{ data.item.submitted === "0"? 'NO':'YES' }} -->
        </template>

        <template #cell(create_time)="data">
          {{ dateFormat(data.item.create_time) }}
        </template>

        <template #cell(update_time)="data">
          {{ dateFormat(data.item.update_time) }}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="kycAdminListChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BLink, BTable, BPagination, BFormRadioGroup, BFormRadio, BCardBody, BTooltip,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import moment from 'moment-timezone'
import { showToast } from '@/libs/tool'
import useAMLJs from './merchants'

const {
  // fetchReviewTransactions,
  // downloadReviewTransactions,
  fetchKycJobcount,
  fetchKycList,
} = useAMLJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BTable,
    BPagination,
    StatisticCardHorizontal,

    BCardBody,
    BFormRadioGroup,
    BFormRadio,
    BTooltip,
  },
  data() {
    return {
      searchRequest: {},
      transactions: [],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 10,
      transactionsTableFields: [
        { key: 'merchant_no', label: 'Merchant ID' },
        { key: 'merchant_name', label: 'Merchant Name' },
        { key: 'submitted', label: 'Submitted for Review' },
        { key: 'review', label: 'DD Review' },
        { key: 'create_time', label: 'Creation Time' },
        { key: 'update_time', label: 'Update Time' },
      ],
      currentPage: 1,
      totalTransactions: 0,
      tzSelected: 'Etc/GMT+3',
      summary: {
        total: 0,
        reviewed: 0,
        unsubmitted: 0,
        pending: 0,
      },
      reviewStatusFilter: 3,
    }
  },
  watch: {
    // currentPage: function refetchSearchData(pageNum) {
    //   this.searchRequest.page_num = pageNum
    //   this.search(this.searchRequest)
    // },
  },
  created() {
    this.fetchKycJobcount()
    this.fetchKycList()
  },
  methods: {
    // kyc info
    fetchKycJobcount() {
      fetchKycJobcount(
        summary => {
          this.summary = summary
        },
        fail => {
          showToast(this, 'Warning', `fetch job count fail with ${fail}.`)
        },
      )
    },
    // kyc list
    fetchKycList() {
      this.transactions = []
      fetchKycList(
        transactions => {
          this.transactions = transactions.list
          this.totalTransactions = transactions.total
          this.dataMeta.from = this.currentPage * this.perPage - (this.perPage - 1)
          this.dataMeta.to = this.totalTransactions - this.currentPage * this.perPage > 0 ? this.currentPage * this.perPage : this.totalTransactions
          this.dataMeta.of = this.totalTransactions
        },
        fail => {
          showToast(this, 'Warning', `fetch list with ${fail}.`)
        },
        {
          id: this.reviewStatusFilter,
          page_num: this.currentPage,
          page_size: this.perPage,
        },
      )
    },
    fastSelected(value) {
      this.currentPage = 1
      this.reviewStatusFilter = value
      this.fetchKycList()
    },
    dateFormat(value) {
      if (!value) {
        return ''
      }
      return moment.tz(moment.tz(value, 'UTC'), this.tzSelected).format('YYYY-MM-DD HH:mm:ss')
    },
    kycAdminListChange(value) {
      this.currentPage = value
      this.fetchKycList()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.td-merchant-no {
  display: inline-block!important;
}
.td-merchant-no:hover {
  text-decoration: underline;
}
</style>
